.namoa-pie-chart {

  width: var(--namoa-pie-chart-size, 100px);
  height: var(--namoa-pie-chart-size, 100px);
  position: relative;
  display: flex;
  place-content: center;
  font-size: 25px;
  font-weight: 600;
  overflow: hidden;
  align-items: center;
  justify-content: center;
	border-radius: 50%;
	border: 1px solid var(--namoa-pie-chart-border-color, black);
	box-sizing: border-box;

  &::before {
    content: "";
    position: absolute;
    border-radius: 50%;
    inset: 0;
    background: conic-gradient(
      var(--namoa-pie-chart-color, gray)
      calc(var(--namoa-pie-chart-percentage, 55)*1%),
      #0000 0
    );
    z-index: 0;
  }

	span {
		z-index: 10;
		font-size: calc(var(--namoa-pie-chart-size)/2.5);
	}

}

mat-nav-list {

	mat-list-item {

		&.mat-list-single-selected-option {
			&:not(:hover) {
				background-color: rgba(0,0,0,.1);
			}
		}

		&.mat-mdc-list-item {
			&[line-height-auto]{
				height: auto;
				min-height: var(--mdc-list-list-item-one-line-container-height);
				box-sizing: border-box;
				padding-top: 10px;
				padding-bottom: 10px;
			}
		}
		[mat-list-item-actions-button]{
			height: 100%;
			margin: 0 -16px 0 0;
			padding: 0 16px 0 0;
			box-sizing: border-box;
		}


	}
}

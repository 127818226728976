@use '@angular/material' as mat;
@use '../mixin-media-pointer.scss';

// @include table-flex-styles.private-table-flex-styles("mat");

[fill-empty]:empty {
	&::before {
		content: attr(fill-empty);
	}
}

// .mat-table.mat-table-responsive {
// 		.mat-cell [fill-empty]:empty {
// 			&::before {
// 				content: attr(fill-empty);
// 			}
// 			// height: 20px;
// 		}
// }

.mat-mdc-table.mat-table-responsive {

	-webkit-border-horizontal-spacing: 0;
  -webkit-border-vertical-spacing: 0;

	.mat-mdc-row.mdc-data-table__row {
		--mdc-typography-subtitle2-line-height: 20px;
		&:hover {
			background-color: transparent;
		}
	}

	.mat-mdc-header-cell {
		font-size: 12px;
		font-weight: 600;
		color: var(--mat-foreground-secondary-text);

		--mat-table-header-headline-line-height: 20px;
		line-height: var(--mat-table-header-headline-line-height);
	}

	@include mixin-media-pointer.mouse {
		.mat-mdc-row {
			&.mat-row-hover {
				&:hover {
					cursor: pointer;
					background-color: var(--mat-background-hover);
				}
			}
		}
	}

	@include mixin-media-pointer.touch {
		.mat-mdc-row {
			&.mat-row-hover {
				&:active {
					cursor: pointer;
					background-color: var(--mat-background-hover);
					.mat-mdc-cell {
						background-color: transparent;
					}
				}
			}
		}
	}

}



main-controller[device="desktop"],
main-controller[device="tablet"] {
	.mat-mdc-table.mat-table-responsive {

		display: table;
		border-spacing: 0;
		background-color: transparent;

		.mat-mdc-header-cell {
			background-color: transparent;
			[mat-sort-header].cdk-keyboard-focused .mat-sort-header-container {
				border-bottom: none;
			}
		}

		// .mat-mdc-header-cell,
		// .mat-cell {
		// 	&:first-of-type {padding-left: 8px;}
		// 	&:last-of-type {padding-right: 8px;}
		// }

		.mat-mdc-row,
		.mat-mdc-header-row {
			display: table-row;
		}

		.mat-mdc-header-cell,
		.mat-mdc-cell {
			&:first-of-type {padding-left: 8px;}
			&:last-of-type {padding-right: 8px;}
			box-sizing: content-box;
			display: table-cell;
			border-bottom: 1px solid var(--mat-foreground-divider);
			vertical-align: middle;
			padding-top: 0;
			padding-right: 8px;
			padding-bottom: 0;
			padding-left: 0;
		}

		.mat-mdc-cell {
			.card-row-label {
				display: none;
			}
		}

	}
}

main-controller[device="phone"] {
	.mat-mdc-table.mat-table-responsive {

		.mat-mdc-header-row {
			display: none;
		}

		> mat-row,
		> tbody > .mat-mdc-row {

			@include mat.elevation(2);

			background-color: var(--mat-background-card);
			display: flex;
			flex-direction: column;
			align-items: normal;
			border-bottom: none;
			border-radius: 4px;
			margin-bottom: 16px;
			padding: 16px;
			height: auto;
			min-height: auto;

			&::after {
				display: none;
			}

		}

		.mat-mdc-cell {

			display: flex;
			flex-direction: column;
			align-items: normal;
			padding: 0;
			margin: 0 0 4px 0;
			min-height: auto;
			border-bottom: none;

		}

		.card-row-label {
			flex: 0 0 auto;
			font-size: 12px;
			line-height: 12px;
			padding: 4px 0;
			// align-self: center;
		}

	}
}

mat-radio-button {
	&[small-line-height]{
		--mdc-radio-state-layer-size: 24px;

		&.mat-mdc-radio-button .mdc-radio {
			padding:
				calc((var(--mdc-radio-state-layer-size) - 20px)/2)
				calc((var(--mdc-radio-state-layer-size) - 20px))
				calc((var(--mdc-radio-state-layer-size) - 20px)/2)
				calc((var(--mdc-radio-state-layer-size) - 20px));

			margin-left: var(--mdc-radio-state-layer-size);
		}

		.mat-mdc-radio-touch-target {
			width: 100%;
			height: 100%;
		}
	}
	// &[label-wrap] {
	// 	.mdc-form-field {
	// 		display: flex;
	// 		flex-direction: row;
	// 	}
	// 	label {
	// 		white-space: normal;
	// 	}
	// }
}

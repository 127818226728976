/* smartphones, touchscreens */
@mixin touch {
	@media (hover: none) and (pointer: coarse) {
		@content;
	}
}

/* stylus-based screens */
// @media (hover: none) and (pointer: fine) {
// 	@content;
// }

/* mouse, touch pad */
@mixin mouse {
	@media (hover: hover) and (pointer: fine) {
		@content;
	}
}

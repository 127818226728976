[translate-wrapper] {
	position: relative;
	.translate-ref {
		position: absolute;
		top: -4px;
		left: -4px;
		min-width: 100%;
		white-space: nowrap;
		display: block;
		background-color: #c4ff00;
		color: #000;
		padding: 4px;
		border-radius: 4px;
		line-height: 1;
		box-shadow:
			0 0 5px 3px #c4ff00,
			0 0 5px 3px black;
	}
}

mat-checkbox {

	&.mat-chip,
	&[small-line-height]{
		--mdc-checkbox-state-layer-size: 24px;
		.mat-mdc-checkbox-touch-target {
			width: 100%;
			height: 100%;
		}
		.mat-mdc-checkbox-ripple,
		input[type=checkbox]:focus~.mdc-checkbox__ripple {
			top: -8px;
			right: -8px;
			bottom: -8px;
			left: -8px;
		}
	}

	&[label-wrap] {
		.mdc-form-field {
			display: flex;
			flex-direction: row;
		}
		label {
			white-space: normal;
		}
	}

	&.mat-chip {

		cursor: pointer;
		border-radius: 16px;
		background-color: var(--mat-background-status-bar);
		padding: 0;
		margin: 0;
		align-items: center;
		transition: background-color .2s ease-in-out;

		&:hover {
			background-color: var(--mat-background-selected-disabled-button);
		}

		&.mat-mdc-checkbox-checked {
			&[color="primary"]{
				background-color: var(--color-palette-primary);
				&:hover {
					background-color: var(--color-palette-primary-400);
				}
				mat-icon,
				mat-label {
					color: var(--color-palette-primary-contrast)
				}
			}
			&[color="accent"]{
				background-color: var(--color-palette-accent);
				&:hover {
					background-color: var(--color-palette-accent-400);
				}
				mat-icon,
				mat-label {
					color: var(--color-palette-accent-contrast)
				}
			}
			&[color="warn"]{
				background-color: var(--color-palette-warn);
				&:hover {
					background-color: var(--color-palette-warn-400);
				}
				mat-icon,
				mat-label {
					color: var(--color-palette-warn-contrast)
				}
			}
		}

		&:has(mat-icon.checked) {
			.mdc-checkbox {
				display: none;
			}
			label.mdc-label {
				padding: 4px 8px;
				mat-label {
					margin-right: -20px;
				}
			}
			&.mat-mdc-checkbox-checked label.mdc-label:not(:has(mat-icon:nth-of-type(2))),
			label.mdc-label:has(mat-icon:nth-of-type(2)){
				mat-label {
					margin-right: 0;
				}
			}
			mat-icon:first-of-type {
				margin-left: 4px;
			}
			mat-icon:nth-of-type(2){
				margin-left: -20px;
			}
			mat-icon.checked,
			mat-icon.not-checked {
				transition: scale .2s ease-in-out;
				width: 20px;
				height: 20px;
				margin-bottom: -5px;
				display: inline-block;
			}
			mat-icon.checked {
				scale: 0;
			}
			mat-icon.not-checked {
				scale: 1;
				color: var(--mat-foreground-disabled);
			}
			&.mat-mdc-checkbox-checked {
				mat-icon.checked {
					scale: 1;
				}
				mat-icon.not-checked {
					scale: 0;
				}
			}
		}

		mat-label {
			font-weight: 400;
		}

	}

}

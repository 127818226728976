.mdc-menu-surface.mat-mdc-select-panel.select-panel-virtual-scroll {

	padding: 0;

  max-height: none !important;
	// min-width: calc(100% + 32px);
	min-width: 300px !important;
	width: var(--select-panel-virtual-scroll-width, auto);

	.select-virtual-mat-option-workaround,
	.select-virtual-multiple-options-workaround {
		display: none;
	}

	.cdk-virtual-scroll-content-wrapper {
		max-width: 100%;
	}

	&.panel-no-max-width {
		max-width: none;
		--select-panel-virtual-scroll-width: 100%;
	}

}

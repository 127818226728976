#app-body{
	.switch-filter-view-type {
		button {

			border: 1px solid var(--mat-foreground-divider);

			&:not(.selected){
				color: var(--color-palette-primary);
			}

			&:not(:disabled):not(.mat-accent):not(.mat-primary):not(.mat-warn) {
				color: var(--color-palette-primary);
				&.selected {
					background-color: var(--color-palette-primary);
					color: var(--color-palette-primary-contrast);
				}
			}

			&:disabled {
				&,
				&.selected {
					color: var(--mat-foreground-disabled);
				}
				&:not(.selected) {
					background-color: var(--mat-background-background);
				}
			}

			&:first-of-type {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}
			&:last-of-type {
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
				border-left: none;
			}
			&:not(:first-child):not(:last-child) {
				border-radius: 0;
				border-left: none;
			}

			mat-icon {
				margin-left: 4px;
			}

			.badge-count {
				background-color: red;
				border-radius: 30px;
				color: #fff;
				font-size: 12px;
				line-height: 12px;
				align-items: center;
				justify-content: center;
				display: flex;
				position: absolute;
				z-index: 10;
				top: -8px;
				right: -8px;
				min-width: 16px;
				height: 16px;
				box-sizing: border-box;
				padding: 0 4px;
				&[total="0"]{
					display: none;
				}
				&::after {
					content: attr(total);
				}
			}
		}
	}
}


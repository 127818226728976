
mat-form-field {

	&[wrapper-no-padding-bottom]{
		.mat-mdc-form-field-subscript-wrapper {
			display: none;
		}
		&[appearance="fill"] {
			.mat-form-field-underline {
				bottom: 0;
			}
		}
	}

	&[wrapper-no-margin]{
		margin-bottom: 0;
		// .mat-mdc-form-field-subscript-wrapper {
		// 	margin: 0;
		// }
	}

	&[input-width-auto]{
		&.mat-mdc-form-field-type-mat-input {
			.mat-mdc-text-field-wrapper {
				.mat-mdc-form-field-infix {
					width: 100%;
				}
			}
		}
	}

	&[input-fill-height]{
		.mat-mdc-text-field-wrapper {
			.mat-mdc-form-field-infix {
				--mat-form-field-filled-with-label-container-padding-top: 0;
				--mat-form-field-filled-with-label-container-padding-bottom: 0;
				.mat-mdc-form-field-input-control {
					height: 55px;
					padding-top: 10px;
					box-sizing: border-box;
				}
			}
		}
	}

	input[type=number]{
		&[spin-button=disabled] {
			&::-webkit-inner-spin-button {
				display: none;
			}
		}
	}

	mat-hint {
		.ipt-loading-spinner {
			position: absolute;
			top: 0;
			left: 8px;
		}
	}

	.mat-mdc-select-arrow-wrapper {
		height: 20px;
	}

	&:not([mat-input-chip]) {
		&:not([mat-select-chip]) {
			&:not([mat-datepicker-chip]) {
				.mat-mdc-text-field-wrapper.mdc-text-field--outlined {
					.mat-mdc-form-field-infix {
						padding-top: 20px;
					}
				}
			}
		}
	}

	.mat-mdc-form-field-icon-suffix {
		mat-icon[matsuffix] {
			color: var(--mat-foreground-icon);
		}
	}

	&.mat-form-field-disabled {
		.mat-mdc-form-field-icon-suffix {
			mat-icon[matsuffix] {
				color: var(--mat-foreground-disabled);
			}
		}
	}

}

.mat-form-field-wrapper {
	> mat-slide-toggle {
		margin-bottom: 20px;
	}
}

.mat-external-hint {
	// font-size: 12px;
	// line-height: 14px;
	@extend .mat-small;
	padding: 4px 16px 8px 16px;
}

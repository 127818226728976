@use '../mixin-media-pointer.scss';
@use '../mixin-scroll-overlay.scss';

#app-body {
	.filter-dashboard {
		overflow: hidden;
		height: 86px;
		box-sizing: border-box;
		padding-bottom: 0;
		.icon-selected {
			width: 20px;
			height: 20px;
			color: var(--color-palette-accent);
			position: absolute;
			top: 4px;
			right: 0;
		}
		.filter-dashboard-card {
			min-width: 150px;
			max-width: 220px;
			position: relative;
			background-color: var(--mat-background-card);
			border: 1px solid var(--mat-foreground-divider);
			border-radius: 4px;
			margin: 0 4px;
			box-sizing: border-box;
			padding: 4px;
			&:first-child {
				margin-left: 0;
			}
			&:last-child {
				margin-right: 0;
			}
			&.size-small {
				min-width: 120px;
				max-width: 120px;
			}
			&.selected {
				border-color: var(--color-palette-accent);
			}
			&.not-clickable {
				overflow-y: auto;
				@include mixin-scroll-overlay.scroll-overlay();
			}
			&:not(.not-clickable){
				cursor: pointer;
				&.selected {
					&:not(.kanban){
						.icon-selected {
							display: block;
						}
					}
				}
				@include mixin-media-pointer.mouse(){
					&:not(.kanban):hover {
						background-color: var(--color-palette-accent-50);
					}
				}
			}
			.total {
				display: flex;
				flex: 1 1 auto;
				justify-content: center;
				align-items: center;
				font-size: 30px;
				line-height: 30px;
				font-weight: 700;
				max-height: 40px;
			}
			.label {
				font-size: 12px;
				line-height: 1;
				color: var(--mat-foreground-secondary-text);
				font-weight: 500;
				flex-basis: min-content;
				text-align: center;
			}
			.label-icon {
				width: 24px;
				height: 24px;
				margin-right: 4px;
				&:not([theme-palette-hue-fg]){
					color: var(--mat-foreground-icon);
				}
			}
			.icon-selected {
				display: none;
				top: 0;
			}

			.card-item {
				cursor: pointer;
				border-radius: 4px;
				margin-left: -2px;
				padding: 2px;
				display: flex;
				flex: 0 0 auto;
				flex-direction: row;
				align-items: center;
				font-size: 12px;
				line-height: 1;
				&.selected {
					.icon-selected {
						display: block;
						width: 14px;
						height: 14px;
						position: static;
						top: auto;
						left: auto;
					}
				}
				@include mixin-media-pointer.mouse {
					&:hover {
						background-color: var(--color-palette-accent-50);
					}
				}
			}

		}
		.kanban {
			min-width: 700px;
			.kanban-status {
				position: relative;
				box-sizing: border-box;
				border-right: 1px solid var(--mat-foreground-divider);
				&:last-child {
					border-right: none;
				}
				&.selected {
					.icon-selected {
						display: block;
					}
				}
				@include mixin-media-pointer.mouse(){
					&:hover {
						background-color: var(--color-palette-accent-50);
					}
				}
				.label {
					text-align: center;
					display: flex;
					flex-direction: column;
					flex: 1 1 auto;
					justify-content: center;
				}
			}
		}
		.step-select {
			display: flex;
			flex: 1 1 auto;
			flex-direction: row;
			align-items: center;
			font-size: 10px;
			line-height: 10px;
			letter-spacing: 1px;
			padding: 2px 4px;
			text-transform: uppercase;
			background-color: var(--mat-background-tooltip);
			color: var(--mat-background-background);
			margin: 0 4px;
			border-radius: 4px;
			.step-select-label {
				display: flex;
				flex: 1 1 auto;
				justify-content: center;
				text-align: center;
				max-height: 20px;
				overflow: hidden;
			}
			.step-select-arrow {
				display: block;
				width: 0;
				height: 0;
				overflow: hidden;
				margin-right: 4px;
				border-left: 4px solid transparent;
				border-top: 4px solid var(--mat-background-background);
				border-bottom: none;
				border-right: 4px solid transparent;
			}
		}
	}

	mat-dialog-content {
		.filter-dashboard {
			height: auto;
			padding-left: 0;
			padding-right: 0;
			margin-top: 16px;
			.kanban {
				min-width: auto;
				.kanban-status {
					border-right: none;
					border-bottom: 1px solid var(--mat-foreground-divider);
					padding: 4px 0;
					&:last-of-type {
						border-bottom: none;
					}
				}
			}
			.filter-dashboard-card {
				margin: 0 0 8px 0;
				min-width: 100%;
				&:last-of-type {
					margin-bottom: 0;
				}
				&.size-small {
					max-width: none;
				}
			}
		}
	}
}

mat-form-field {
	&.mat-mdc-form-field-type-mat-input {
		.mat-mdc-form-field-infix {
			width: 156px;
		}
	}
	&.mat-mdc-form-field-type-mat-select {
		cursor: pointer;
		.mdc-floating-label {
			cursor: pointer;
		}
	}
	&.mat-mdc-form-field-type-mat-input,
	&.mat-mdc-form-field-type-mat-select,
	&.mat-mdc-form-field-type-mat-date-range-input {

		&[mat-select-chip],
		&[mat-select-reset-button]{
			.mat-select-trigger {
				display: flex;
			}
			.mat-select-value {
				display: flex;
				max-width: none;
				flex-direction: column;
			}

		}

		&[mat-input-chip],
		&[mat-datepicker-chip],
		&[mat-select-chip] {

			&[mat-floating-label-max-width] {
				// .mdc-notched-outline--upgraded .mdc-floating-label--float-above
				.mdc-floating-label {
					max-width: max-content;
				}
			}


			&.mat-mdc-form-field-appearance-outline {
				.mat-mdc-form-field-icon-suffix {
					top: 0;
				}
			}

			.mat-mdc-text-field-wrapper {
				padding-right: 0;
				padding-left: 0;
			}
			.mat-mdc-form-field-flex {
				cursor: pointer;
				border-radius: 16px;
				background-color: var(--mat-background-status-bar);
				padding: 0 8px;
				margin: 0;
				align-items: center;
				&:hover {
					background-color: var(--mat-background-selected-disabled-button);
				}
			}
			&.mat-focused.focused-glow-effect {
				.mat-mdc-form-field-flex {
					box-shadow:
						0 0 0px 2px var(--color-palette-primary),
						0 0 6px 0 var(--color-palette-primary);
					background-color: var(--color-palette-primary-50);
				}
			}
			&[mat-input-chip] {
				.mat-mdc-form-field-flex {
					cursor: text;
				}
			}
			.mat-mdc-form-field-infix {
				height: 32px;
				min-height: 32px;
				padding: 6px 0 0 2px;
				box-sizing: border-box;
				border-top: none;
			}
			.mat-select-trigger {
				display: flex;
			}
			.mat-select-value {
				display: flex;
				max-width: none;
				.mat-select-value-text {
					display: block;
					width: 100%;
				}

			}
			.mdc-floating-label {
				top: 15px;
				left: 10px;
				&.mdc-floating-label--float-above {
    			--mat-mdc-form-field-label-transform: translateY( -27.75px) scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
				}
			}
			&.mat-mdc-form-field-has-icon-prefix {
				.mdc-floating-label {
					left: 20px;
				}
			}
			// &.mat-mdc-form-field-can-float.mat-mdc-form-field-should-float {
			// 	.mat-mdc-form-field-label-wrapper .mat-mdc-form-field-label {
			// 		// extending Angular Material CSS
			// 		transform: translateY(-1.5em) scale(0.75) translateX(0);
			// 	}
			// }
			// &[mat-select-chip=prefix].mat-mdc-form-field-can-float.mat-mdc-form-field-should-float {
			// 	.mat-mdc-form-field-label-wrapper .mat-mdc-form-field-label {
			// 		// extending Angular Material CSS
			// 		transform: translateY(-1.5em) scale(0.75) translateX(-2.5em);
			// 	}
			// }
			// .mat-mdc-form-field-label-wrapper {
			// 	top: -14px;
			// 	font-weight: 500;
			// 	overflow: visible;
			// 	.mat-mdc-form-field-label {
			// 		color: var(--mat-foreground-text);
			// 	}
			// }
			.mat-mdc-select-arrow-wrapper {
				transform: none;
				width: 20px;
				height: 20px;
				background-color: var(--mat-foreground-icon);
				border-radius: 20px;
				// margin-left: 4px;
				display: flex;
				flex: 0 0 auto;
				align-items: center;
				justify-content: center;
				margin-left: 4px;
				margin-top: 0;
				&::before {
					display: block;
					content: " ";
					overflow: hidden;
					width: 0;
					height: 0;
					border-top: 5px solid var(--mat-background-status-bar);
					border-right: 5px solid transparent;
					border-bottom: 0;
					border-left: 5px solid transparent;
				}
				.mat-mdc-select-arrow {
					display: none;
				}
			}
			.mat-mdc-form-field-icon-prefix,
			.mat-mdc-form-field-icon-suffix {
				top: 0;
				display: flex;
				mat-icon {
					box-sizing: border-box;
    			padding: 0;
				}
			}
			.mat-mdc-form-field-icon-prefix {
				mat-icon {
					margin-right: 4px;
				}
			}
			.mat-datepicker-toggle {
				line-height: 0;
				margin-right: 0;
				margin-left: -2px;
				.mat-mdc-icon-button {
					width: 22px;
					height: 22px;
					padding: 0;
					--mdc-icon-button-icon-size: 100%
				}
			}
			.mdc-notched-outline {
				.mdc-notched-outline__leading,
				.mdc-notched-outline__notch,
				.mdc-notched-outline__trailing {
					border: none;
				}
				.mdc-notched-outline__leading {
					display: none;
				}
			}
			.mat-datepicker-input {
				cursor: pointer;
			}
		}

		&[mat-select-reset-button],
		&[mat-datepicker-reset-button] {

			.mat-mdc-form-field-icon-suffix {
				display: none;
			}

			&:not([mat-select-chip]):not([mat-datepicker-chip]).mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper {
				padding-right: 16px;
			}

			// &[mat-select-chip]:not(.mat-mdc-form-field-has-icon-prefix) .mat-mdc-text-field-wrapper {
			// 	padding-left: 0;
			// }

			&.has-value {
				.mat-mdc-form-field-infix {
					width: 156px;
				}
				.mat-datepicker-toggle,
				.mat-mdc-select-arrow-wrapper {
					display: none;
				}
				.mat-mdc-form-field-icon-suffix {
					top: 0;
					display: flex;
				}
			}
			.mat-mdc-select-arrow-wrapper {
				// transform: none;
				display: flex;
				flex-direction: column;
				justify-content: center;
			}

			&:not([mat-datepicker-reset-button]:not(.has-value)) {
				.mat-mdc-form-field-icon-suffix {
					// display: none;
					cursor: pointer;
					background: var(--mat-foreground-icon);
					padding: 0;
					width: 20px;
					height: 20px;
					border-radius: 50%;
					box-sizing: border-box;
					margin-left: 4px;
					mat-icon.button-reset {
						box-sizing: border-box;
						padding: 5px;
						line-height: 0;
						width: 100%;
						height: 100%;
						color: var(--mat-background-status-bar);
					}
				}
			}

			&[mat-datepicker-reset-button] {
				.mat-mdc-form-field-icon-suffix {
					display: flex;
				}
				&:not([mat-datepicker-chip]) {
					.mat-mdc-form-field-icon-suffix {
						mat-datepicker-toggle {
							margin-right: -13px;
						}
					}
				}
				mat-icon.button-reset {
					display: none;
				}
				&.has-value {
					&:not([mat-datepicker-chip]){
						.mat-mdc-form-field-icon-suffix {
							margin-left: 7px;
							// margin-right: 8px
						}
					}
					mat-datepicker-toggle {
						display: none;
					}
					mat-icon.button-reset {
						display: flex;
					}
				}
			}

		}

		&[mat-datetime-picker-reset-button] {
			.mat-mdc-form-field-icon-suffix {
				cursor: pointer;
				background: var(--mat-foreground-icon);
				padding: 0;
				width: 20px;
				height: 20px;
				border-radius: 50%;
				box-sizing: border-box;
				margin-left: 4px;

				mat-icon.button-reset {
					box-sizing: border-box;
					padding: 5px;
					line-height: 0;
					width: 100%;
					height: 100%;
					color: var(--mat-background-status-bar);
				}
			}
		}
	}
}

.cdk-overlay-pane {
	&.bottom-sheet-no-padding {
		.mat-bottom-sheet-container {
			padding: 0;
		}
	}
	&.namoa-bottom-sheet {
		.mat-bottom-sheet-container {
			border-radius: 8px 8px 0 0;
		}
	}
}

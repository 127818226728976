/* You can add global styles to this file, and also import other style files */

@import "./styles/themes";
@import "./styles/angular-material.layout-attributes";

@import "./styles/helpers-gutters";
@import "./styles/helpers-position";
@import "./styles/helpers-text";
@import "./styles/helpers-image";

@import "./styles/formly/overrides";
@import "./styles/material/snackbar";
@import "./styles/material/mat-button";
@import "./styles/material/mat-checkbox";
@import "./styles/material/mat-dialog";
@import "./styles/material/mat-radio-button";
@import "./styles/material/mat-cdk-overlay-pane";
@import "./styles/material/mat-select-chip";
@import "./styles/material/mat-form-field";
@import "./styles/material/mat-icon";
@import "./styles/material/mat-timepicker";
@import "./styles/material/mat-list-item";
@import "./styles/material/mat-select-virtual-scroll";
@import "./styles/material/mat-table-responsive";
@import "./styles/material/mat-title";
@import "./styles/material/mat-bottom-sheet";
@import "./styles/material/mat-menu";

@import "./styles/translate-directive.scss";

@import "./styles/namoa/filter-dashboard.scss";
@import "./styles/namoa/switch-filter-view-type.scss";
@import "./styles/namoa/pie-chart.scss";

// for npm roboto-fontface package (to load local files)
// node_modules/roboto-fontface/fonts/roboto
$roboto-font-path: "../fonts" !default;
@import "../node_modules/roboto-fontface/css/roboto/sass/roboto-fontface";

// for npm material-icons package (to load local files)
$material-icons-font-path: './';
@import '../node_modules/material-icons/iconfont/material-icons.scss';

html[browser=safari] {
	// min-height: calc(100vh - env(safe-area-inset-bottom));
	// height: 100%;
	body {
		min-height: -webkit-fill-available;
	}
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

#app-body{
	mat-snack-bar-container {

		&.success {
			--mdc-snackbar-container-color: var(--color-palette-success-800);
			--mdc-snackbar-supporting-text-color: var(--color-palette-success-800-contrast);
			--mat-snack-bar-button-color: var(--color-palette-success-50-contrast);
		}

		&.notFound,
		&.payloadError,
		&.warn {
			--mdc-snackbar-container-color: #E2D23C;
			--mdc-snackbar-supporting-text-color: rgba(0, 0, 0, 0.7);
			--mat-snack-bar-button-color: var(--color-palette-primary);
		}

		&.unauthorized,
		&.forbidden,
		&.serverError,
		&.error {
			--mdc-snackbar-container-color: var(--color-palette-warn);
			--mdc-snackbar-supporting-text-color: var(--color-palette-warn-contrast);
			--mat-snack-bar-button-color: var(--color-palette-primary-900);
		}

		&.mobile-width-100 {
			width: 100%;
		}

	}
}

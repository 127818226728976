// .timepicker {
// 	.mat-toolbar-single-row {
// 		height: 80px;
// 	}
// }

ngx-mat-timepicker-time-control {
	.ngx-mat-timepicker-control.mat-mdc-form-field {
		margin-bottom: 0;
		.mat-mdc-text-field-wrapper.mdc-text-field.mdc-text-field--outlined {
			.mat-mdc-form-field-flex {
				.mat-mdc-form-field-infix {
					padding-top: 12px;
				}
			}
		}
	}
}

.mat-mdc-form-field.mat-mdc-form-field-has-icon-suffix {
	&:not([mat-datepicker-chip]){
		.mat-datepicker-toggle {
			padding: 0 8px 0 0;
			button.mat-mdc-icon-button {
				--mdc-icon-button-state-layer-size: 24px;
				margin-right: 4px;
				padding: 0;
			}
		}
	}
}

.ngx-mat-timepicker-control.mat-mdc-form-field.mat-mdc-form-field-type-mat-input.mat-mdc-form-field-has-icon-suffix.mat-form-field-appearance-outline {
	.mat-mdc-form-field-infix {
		min-height: 42px;
		padding-top: 10px;
		padding-bottom: 4px;
	}
	.mat-mdc-form-field {
		margin: 0;
		padding: 0;
	}
	.mat-mdc-form-field-icon-suffix {
		padding-right: 4px;
	}
	// .mat-form-field-infix {
	// 	padding-top: 18px;
	// 	border-top: 0;
	// 	padding-right: 16px;
	// 	padding-bottom: 14px;
	// }
	// .mat-form-field-suffix {
	// 	top: 1px;
	// }
	.mat-mdc-form-field-subscript-wrapper {
		display: none;
	}
}

@mixin scroll-overlay(){

	// overflow: hidden !important;

	// &:hover {

		// & {
		// 	overflow: auto !important;
		// 	overflow: overlay !important;
		// }

		&::-webkit-scrollbar {
			width: 12px;
			height: 12px;
		}

		&::-webkit-scrollbar-track {
			background-color: transparent;
		}

		&::-webkit-scrollbar-thumb {
			&:hover {
				background-color: rgba(0,0,0,0.3);
			}
			&:active {
				background-color: rgba(0,0,0,0.4);
			}
			background-clip: padding-box;
			border: 2px solid transparent;
			background-color: rgba(0,0,0,0.2);
			border-radius: 10px;
		}

		&::-webkit-scrollbar-button {
			height: 0;
			width: 0;
			background: transparent;
		}

	// }

}

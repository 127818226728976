a,
button {
	&[mat-button],
	&[mat-stroked-button],
	&[mat-flat-button],
	&[mat-raised-button],
	&[mat-menu-item] {
		&[layout=column]{
			&.mdc-button {
				justify-content: center;
			}
			&.mat-mdc-menu-item.mdc-list-item {
				align-items: inherit;
			}
			.mat-mdc-menu-item-text {
				flex: none;
			}
		}
		&[layout-align]{
			&:not([layout]) {
				flex-direction: column;
			}
		}
		&[touch-target-same-height]{
			.mat-mdc-button-touch-target {
				height: 100%;
			}
		}

		&[button-label-same-width] {
			.mdc-button__label {
				width: 100%;
				min-height: inherit;
			}
		}
	}

	// workaround due to issue: https://github.com/angular/components/issues/26056
	&[mat-flat-button],
	&[mat-raised-button]{
		&[color="primary"]{
			&.mat-mdc-raised-button.mat-primary,
			&.mat-mdc-unelevated-button.mat-primary {
				--mdc-filled-button-label-text-color: var(--color-palette-primary-contrast);
				--mdc-protected-button-label-text-color: var(--color-palette-primary-contrast);
			}
		}
		&[color="accent"]{
			&.mat-mdc-raised-button.mat-accent,
			&.mat-mdc-unelevated-button.mat-accent {
				--mdc-filled-button-label-text-color: var(--color-palette-accent-contrast);
				--mdc-protected-button-label-text-color: var(--color-palette-accent-contrast);
			}
		}
		&[color="warn"]{
			&.mat-mdc-unelevated-button.mat-warn {
				--mdc-filled-button-label-text-color: var(--color-palette-warn-contrast);
			}
		}
	}

}

.mat-mdc-form-field {

	margin-bottom: 8px;
	.mat-form-field-flex {
		display: flex;
		flex-direction: row;
	}

	.prefix {
		display: block;
		padding-right: 4px;
	}

	.suffix {
		display: block;
		padding-left: 4px;
	}

	span.prefix,
	span.suffix {
		span {
			position: relative;
			top: -4px;
		}
	}


}

input[text-align="right"]{
	text-align: right;
}
